import React from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Layout/Head"
import PageHero from "../components/Layout/PageHero"
import FP03Work from "../components/FrontPage/FP03Work"

const WorkPage = () => {
  return (
    <Layout>
      <Head title="Our Work" />
      <PageHero
        title="Our Work"
        description="As an empathy-based design agency, we bring brands to life at key moments in the customer journey that stand to make the greatest impact."
        videoUrl="https://videos.ctfassets.net/d0u2w5k6pl1p/5icnZVtBEnpeHsRqELGncb/e7f09c52892bd9deffc11ee383392968/TB_WESBITE_About_Page_video.mp4"
      />
      <FP03Work />
    </Layout>
  )
}

export default WorkPage
